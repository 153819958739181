.container {
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.form {

}

.previewWrapper {
    padding-left: 2rem;
    padding-right: 2rem;
    h2 {
        text-align: center;
    }
}
.preview {
    display: flex;
    flex-direction: column;

    p {
        margin-bottom: 0;
    }

    h2 {
        text-align: left;
    }
}

.inputGroup {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 16px;

    .textArea {
        width: 100%;
        resize: vertical;
    }

}

.image {
    margin-top: 16px;
    object-fit: cover;
    height: 300px;
    object-position: center;
    border-radius: 20px;
}

.coverWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    height: 250px;
}

.coverImage {
    width: 100%;
    height: 250px;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.7);
}

.coverTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.6vw;
    margin: 0;
    font-weight: 700;
    text-align: center;

    @media (max-width: 1200px) {
        font-size: 2.5vw;
    }

    @media (max-width: 768px) {
        font-size: 3.5vw;
    }

}

.formItem {
    margin-bottom: 0;
    width: 100%;
    min-height: 80px;
}

.button:global(.ant-btn) {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
}

.redButton {
    composes: button;
    background-color: red;
    &:hover {
        background-color: mediumpurple !important;
    }
}
