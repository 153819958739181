.layout {
  background-color: #D3DCDE;
}

.sider {
  overflow-y: scroll;
  position: sticky !important;
  overflow-x: hidden;
  min-height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 8px 0px;
  background-color: #1f1f1f !important;

  :global(.ant-layout-sider-trigger) {
    background-color: #1f1f1f !important;
  }

  :global(.ant-layout-sider-children) {
    background-color: #1f1f1f !important;
    height: 100vh !important;
  }
}

.header {
  z-index: 1;
  display: block !important;
  background-color: transparent !important;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.logoContainer {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: height 0.2s linear;

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.collapsed {
    height: 64px;
  }
}

.menu {
  margin-top: 64px;
  background-color: #1f1f1f;
  color: white;
}
.logoutButton {
  background-color: #e14d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 12px;
    margin-right: 12px;

  @media screen and (max-width: 768px) {
    margin: 16px;
    margin-bottom: 72px;

  }
}
