.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 24px;


    .logoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 36px;

        .logo {
            width: 250px;
        }
    }

}
